// src/components/CheckoutForm.js
import React, { useState } from 'react';
import QRCodePayment from './QRCodePayment'
import emailjs from "emailjs-com";
import { useNavigate } from "react-router-dom";
import { useCart } from './CartContext';
const CheckoutForm = ({ onFormSubmit,amount,cartdetail }) => {
  const navigate = useNavigate();
  const {  clearCart } = useCart();
  const [formData, setFormData] = useState({
   
    email: '',
    mobileno:'',
    firstname: '',
    lastname: '',
    company:'',    
    address: '',
    apartment:'',
    city:'',
    country:'India',
    state:'',
    postalcode:''
  });
  const [alert1, setAlert] = useState('');
  const [qrenable, setQrenable] = useState(false);
 // const [orderId, setOrderId] = useState("");
  const [currentDate, setCurrentDate] = useState("");
// Function to generate order ID
const generateOrderId = () => {
  const currentDate = new Date();

  // Format date as YYYYMMDD
  const date = currentDate
    .toISOString()
    .slice(0, 10)
    .replace(/-/g, "");

  // Format time as HHMMSS
  const time = currentDate
    .toTimeString()
    .slice(0, 8)
    .replace(/:/g, "");

  // Get last 4 digits of mobile number
  const lastFourDigits = formData.mobileno.slice(-4);

  // Combine date, time, and last 4 digits of mobile number
  const generatedOrderId = `${date}${time}${lastFourDigits}`;

  //setOrderId(generatedOrderId);
  return generatedOrderId;
};
const generateDate = () => {
  const date = new Date();

    // Get year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(date.getDate()).padStart(2, '0'); // Ensure day is always two digits

    // Format the date as YYYY-MM-DD
    const formattedDate = `${year}-${month}-${day}`;

    setCurrentDate(formattedDate);
    return formattedDate;
}
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const validatePincode = (e) => {
    const { name, value } = e.target;
    debugger;
    
    const pinRegex = /^[1-9][0-9]{5}$/; // Indian pincode format
    //if (/^\d{0,6}$/.test(value)) {
    if (value.length > 6) {
     // setAlert("Invalid Pincode! Must be a 6-digit number.");
      alert('Invalid Pincode! Must be a 6-digit number.')
    } else {
      setAlert("");
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const handleQRFormSubmit = (transactionid,type,shipandamount) => {
    debugger;
    // Logic for placing the order (this could involve API calls, updating state, etc.)
    console.log("Order has been placed successfully!");
  const orderId=  generateOrderId();
   const orderdate= generateDate();
    const estimatedDelivery=orderdate;
   const products= cartdetail.map((product) => (
      {
         product_name: product.name, quantity: product.qty, price: product.discountPrice 
      }
    ))
      // { product_name: "Product 1", quantity: 2, price: "20" },
        // { product_name: "Product 2", quantity: 1, price: "50" }
     // Set up the template parameters
     const templateParams = {
      to_mail_id:formData.email , //"kkdhoomkarthick@gmail.com",  //"snarendran37@gmail.com",
      cc_mail_id:"snarendran37@gmail.com",
      from_name:"AARANG JEWELS" ,//"karthick",
      customer_name:formData.firstname, // "Kumar",
      order_number:orderId, //"123456",
      order_date:orderdate, //"2024-10-16",
      items:       
        products
         // { product_name: "Product 1", quantity: 2, price: "20" },
        // { product_name: "Product 2", quantity: 1, price: "50" }
      ,
      total_price: shipandamount,//amount,//"70",
      shipping_address:formData.address+ " " + formData.apartment, //"1234  St",
      city_state_zip:formData.city+ " " + formData.state+ " " + formData.postalcode,  //"chennai, TN, 62704",
      shipping_method: "Delhivery",//"BlueDart",
      estimated_delivery_date:"7 to 8 working days",//estimatedDelivery,// "2024-10-22",
      payment_method:type=='upi'? "UPI":"COD",
      billing_address:formData.address+ " " + formData.apartment +" " + formData.city+ " " + formData.state+ " ", //"1234  St, chennai, TN",
      company_name:"AARANG JEWEL",
      transaction_id:type=='upi'?transactionid:"NOT APPLICABLE",
      contact_no:formData.mobileno
    };

    emailjs
      .send(
        "service_w0ne9x5",//"service_bhu7yve" mine, // Replace with your service ID
        "template_si0mcwg",//"template_g1swvzj mine", // Replace with your template ID
        templateParams,
        "cItItMrxHrbtf9ViB",//"SnzHsAMEBh8B80K1g" // Replace with your public key (user ID)
      )
      .then(
        (result) => {
          console.log(result.text);
          clearCart();
          navigate(`/orderplace`, { state: { orderId,shipandamount,estimatedDelivery } });
        },
        (error) => {
          console.log(error.text);
        }
      );
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    debugger;
    if (formData.postalcode.length<6 ){
alert('Minimum 6 digits need for postal code')
return false;
    }
    if (!formData.firstname || !formData.email || !formData.address) {
      setAlert('All fields are required.');
    } else {
      setAlert('');
     // onFormSubmit(formData);
      //handleOrderPlacement()
      setQrenable(true);
    }
  };
 
  return (
    <div className="container mx-auto p-8old">
         {!qrenable&&(
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Contact Information */}
        <div>
          <h2 className="text-xl font-semibold">Contact information</h2>
          <input
            type="email"
            name="email"
            placeholder="Email address"
            value={formData.email}
            onChange={handleInputChange}
            required
            className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          
          <div className="mt-6">
            <input
              type="text"
              name="mobileno"
              placeholder="Mobile No"
              value={formData.mobileno}
              onChange={handleInputChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
        </div>

        {/* Shipping Information */}
        <div>
          <h2 className="text-xl font-semibold">Shipping information</h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-2">
            <input
              type="text"
              name="firstname"
              placeholder="First name"
              value={formData.firstname}
              onChange={handleInputChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            <input
              type="text"
              name="lastname"
              placeholder="Last name"
              value={formData.lastname}
              onChange={handleInputChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>

          <div className="mt-6">
            <input
              type="text"
              name="company"
              placeholder="Company (optional)"
              value={formData.company}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>

          <div className="mt-6">
            <input
              type="text"
              name="address"
              placeholder="Address"
              value={formData.address}
              onChange={handleInputChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>

          <div className="mt-6">
            <input
              type="text"
              name="apartment"
              placeholder="Apartment, suite, etc. (optional)"
              value={formData.apartment}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
            <input
              type="text"
              name="city"
              placeholder="City"
              value={formData.city}
              onChange={handleInputChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            <div>
              <label className="block mb-2">Country</label>
              <select
                name="country"
                value={formData.country}
              //  onChange={handleInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >            <option selected={true}>India</option>
                
              </select>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
            <input
              type="text"
              name="state"
              placeholder="State / Province"
              value={formData.state}
              onChange={handleInputChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            <input
             type="number" 
              name="postalcode"
              placeholder="Postal code"
              value={formData.postalcode}
            //  onChange={handleInputChange}
              onChange={validatePincode}
               maxLength="6"
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>

        </div>     

        {/* Submit Button */}
        <div className="mt-6">
          <button
            type="submit"
            className="w-full bg-indigo-600 text-black py-2 rounded-md  focus:outline-none focus:ring-2 focus:ring-indigo-500 hover:bg-blue-600 hover:text-indigo-600"
          >
            Continue to Payment
          </button>
        </div>
      </form>)}
         {/* Delivery Method */}
         {qrenable&&(
 <div>
 <h2 className="text-lg font-medium text-gray-900 mt-3 ml-2">Payment </h2>
 {/* Add your delivery method inputs here */}
 <QRCodePayment amount={amount} handleQRFormSubmit={handleQRFormSubmit}/>
     {/* Direct UPI payment link */}  
</div>
         )}
        
    </div>
  );
  
};

export default CheckoutForm;
