import React from 'react';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import ProductList from './components/ProductList';
import ProductDetail from './components/ProductDetail';
import Cart from './components/Cart';
import NavBar from '../src/components/NavBar/NavBar';
import Promo from '../src/components/Promo/OverLappingImage';
import ImageSliderShow from './components/ImageSlider/ImageSliderShow';
import HeroSection from './components/HeroSection/HeroSection';
import PerkSection from './components/PerkSection/PerkSection';
import FooterSection from './components/Footer/Footer';
import TrendingProducts from './components/TrendingProducts/TrendingProducts';
import ProductHome from './components/ProductListHomePage/ProductHome';
import Home from './components/Home';
import ThankYouPage from './components/ThankYouPage';
import RefundPolicy from './components/RefundPolicy';
import PrivacyPolicy from './components/PrivacyPolicy';
import Contactus from './components/Contactus';
import TermsOfService from './components/TermsOfService';
import AccessibilityPolicy from './components/AccessibilityPolicy';
import ReturnExchangePolicy from './components/ReturnExchangePolicy';
import ShippingAndDelivery from './components/ShippingAndDelivery';
import OrderTracking from './components/OrderTracking';
import FAQ from './components/Faq';
import Search from './components/Search';
import QRCode from './components/QRCode';


const App = () => {
  const APP_VERSION = "1.0.1"; // Increment on each deployment

if (localStorage.getItem("app_version") !== APP_VERSION) {
    localStorage.setItem("app_version", APP_VERSION);
    window.location.reload(true); // Force refresh
}

  return (
    <Router>
      <div>
        {/* <nav className="bg-gray-800 p-4">
          <div className="container mx-auto">
            <a href="/" className="text-white text-lg font-bold">My </a>
          </div>
        </nav> */}
        <NavBar></NavBar>

<Routes >
        <Route path="/" exact element={<Home/>} />
          <Route path="/product" exact element={<ProductHome/>} />
          <Route path="/product/:id" element={<ProductDetail/>} />
          <Route path="/cart" element={<Cart/>} />
          <Route path="/orderplace" element={<ThankYouPage/>} />
          <Route path="/refundpolicy" element={<RefundPolicy/>} />
          <Route path="/privatepolicy" element={<PrivacyPolicy/>} />
          <Route path="/contactus" element={<Contactus/>} />
          <Route path="/termsservice" element={<TermsOfService/>} />
          <Route path="/accessbilityservice" element={<AccessibilityPolicy/>} />
          <Route path="/returnexchange" element={<ReturnExchangePolicy/>} />
          <Route path="/shipdelivery" element={<ShippingAndDelivery/>} />
          <Route path="/ordertrack" element={<OrderTracking/>} />
          <Route path="/faq" element={<FAQ/>} />
          <Route path="/imageslider" element={<ImageSliderShow/>} />
          <Route path="/bn" element={<Search/>} />
          <Route path="/qr" element={<QRCode/>} />
        </Routes >
{/* <HeroSection />
<TrendingProducts/>
<PerkSection/> */}
{/* <ProductHome></ProductHome> */}
<FooterSection/>
{/* <Promo></Promo> */}       
      </div>
    </Router>
  );
};

export default App;
